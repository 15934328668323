import axios from 'axios';
import React from 'react';

const Lang = ({ lang, selected, id, setMyLang }) => {

  const handlerClick = (ev) => {
    axios.post('https://connivence-salon.net/api/e-learning/users/lang.php', new URLSearchParams({
      id, lang
    })).then(response => {
      sessionStorage.setItem("lang", lang);
      setMyLang(lang);
      /*setIsOpened(false); // Fermer le popup après succès
      closePopup();*/
    }).catch(error => {
      // Gérer l'erreur ici, par exemple en affichant un message d'erreur
      console.error('Erreur lors de l\'envoi de la requête:', error);
      // Peut-être définir un état pour afficher l'erreur dans le popup
    });
  }
  return (
    <li>
      <label className="radio-img">
        <input type="radio" name="lang" defaultChecked={selected} />
        <div onClick={handlerClick} className='flag' style={{ backgroundImage: 'url("../assets/images/flags/' + lang + '.svg")' }} ></div>
      </label>
    </li>
  );
};

export default Lang;