import React from 'react';
import { Link } from 'react-router-dom';

const LessonButton = ({ lesson }) => {
  return (
    <li className='lesson-button'>
      <Link to={"/file/" + lesson.id} dangerouslySetInnerHTML={{ __html: "<strong>" + lesson.category_name + "</strong>" + lesson.title }}></Link>
    </li>
  );
};

export default LessonButton;