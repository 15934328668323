import React from 'react';

const vibrantColors = [
  "#FF5733", // Vivid Red
  "#FF8D1A", // Vivid Orange
  "#FFC300", // Vivid Yellow
  "#DAF7A6", // Vivid Light Green
  "#28B463", // Vivid Green
  "#1F618D", // Vivid Blue
  "#8E44AD", // Vivid Purple
  "#FF33F6", // Vivid Pink
  "#FF33FF", // Vivid Magenta
  "#33FFD1"  // Vivid Cyan
];

const ProfilePicture = ({ user }) => {

  const getBgColor = () => {
    return vibrantColors[Math.floor(Math.random() * vibrantColors.length)];
  }
  return (
    <div className='profile-picture' style={{ backgroundColor: getBgColor() }}>
      <p>{user?.prenom?.substring(0, 1).toUpperCase()}</p>
    </div>
  );
};

export default ProfilePicture;