import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ visible, active }) => {

  useEffect(() => {
    
  }, [])

  return (
    <footer className={visible ? "" : "invisible"}>
      <nav>
        <ul>
          <li>
            <Link to="/lessons" className={active === "lessons" ? "active" : ""}>
              <img src="./assets/images/hat.png" alt="Parcourez les fiches d'apprentissage Connivence" />
            </Link>
          </li>
          <li>
            <Link to="/home" className={active === "home" ? "active" : ""}>
              <img src="./assets/images/trophy.png" alt="Formez-vous en compagnie de vos amis" />
            </Link>
          </li>
          <li>
            <Link to="/profile" className={active === "profile" ? "active" : ""}>
              <img src="./assets/images/user.png" alt="Paramétrez votre compte Connivence Academy" />
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;