import React, { useEffect } from 'react';
import Emoji from './Emoji';
import LessonFile from './LessonFile';
import axios from 'axios';

const GameLesson = ({ files, changeState, user, defi }) => {

  const endGame = () => {
    changeState();
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto' // Cette ligne est facultative car 'auto' est le comportement par défaut
    });
    document.body.classList.add("day-mode");
    document.body.classList.add("files-bg");
    return () => {
      document.body.classList.remove("day-mode");
      document.body.classList.remove("files-bg");
    }
  }, [])
  return (
    <section className="game-lesson" >
      <div className="lesson-intro">
        <Emoji src="files" />
        <p>
          Félicitations ! <strong>Vous avez terminé le défi de la semaine avec succès</strong> 🥳 Vous avez donc débloqué de nouvelles fiches de cours.
        </p>
        <p>
          Ne partez-pas ! Vous pourrez récolter vos points et améliorer vos récompenses après avoir lu ce chapitre...
        </p>
      </div>

      {
        files.map((file, index) =>
          <LessonFile title={file.title} content={file.content} key={index} />
        )
      }



      <button onClick={() => endGame()}>Suivant</button>
    </section>
  );
};

export default GameLesson;