import React from 'react';

const LessonFile = ({ content, title }) => {
  return (
    <div className='lesson' dangerouslySetInnerHTML={{ __html: "<h2>" + title + "</h2>" + content }}>

    </div>
  );
};

export default LessonFile;