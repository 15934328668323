import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Home = ({ user, isConnected }) => {

  const [myRank, setMyRank] = useState();
  const [availability, setAvailabity] = useState(-1);

  useEffect(() => {
    isConnected();

    if (!document.body.classList.contains("fingers"))
      document.body.classList.add("fingers");

    axios.get('https://connivence-salon.net/api/e-learning/users/ranking.php', {
      params: {
        user: JSON.parse(localStorage.getItem("user"))[0]?.id
      }
    })
      .then(response => {
        if (response.data) {
          setMyRank(response.data.me.rank);
        }
        // Traiter la réponse ici
      })
      .catch(error => {
        console.error('Erreur:', error);
        // Gérer l'erreur ici
      });

    axios.get('https://connivence-salon.net/api/e-learning/game/get/availability.php', {
      params: {
        user: JSON.parse(localStorage.getItem("user"))[0]?.id
      }
    })
      .then(response => {
        if (response.data) {
          if (parseInt(response.data.success) === 0) {
            setAvailabity(1);
          }
          else {
            //REMETTRE CA
            //setAvailabity(0);
            setAvailabity(1);

          }

        }
        // Traiter la réponse ici
      })
      .catch(error => {
        console.error('Erreur:', error);
        // Gérer l'erreur ici
      });
  }, [])

  return (
    <>
      <Header />
      <main className="home-component">
        <h1>{user.points}<span>pts</span></h1>
        <p>Vous êtes <strong>{myRank}{parseInt(myRank) > 1 ? "ème" : "er"}</strong> au <strong><Link to={"/ranking"}>classement</Link></strong> ! Tenez bon !</p>
        {availability < 1 ?
          <div className='button-like unavailable'>

            {
              availability === -1 ?
                "Chargement..."
                :
                <>
                  <svg width="121" height="138" viewBox="0 0 121 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M108.036 60.375H101.554V40.9688C101.554 18.382 83.1335 0 60.5 0C37.8665 0 19.4464 18.382 19.4464 40.9688V60.375H12.9643C5.80692 60.375 0 66.1699 0 73.3125V125.062C0 132.205 5.80692 138 12.9643 138H108.036C115.193 138 121 132.205 121 125.062V73.3125C121 66.1699 115.193 60.375 108.036 60.375ZM79.9464 60.375H41.0536V40.9688C41.0536 30.2684 49.7775 21.5625 60.5 21.5625C71.2225 21.5625 79.9464 30.2684 79.9464 40.9688V60.375Z" fill="white" />
                  </svg>
                  La suite la semaine prochaine...
                </>
            }
          </div>
          :
          <Link to={"/game"} className="button-like">Faire le défi de la semaine
            <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 1L14 5.002L10 9.003M1 5.003H14" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        }
      </main>
      <Footer visible={true} active={"home"} />
    </>
  );
};

export default Home;