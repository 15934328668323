import React from 'react';
import ProfilePicture from './ProfilePicture';

const RanksItem = ({ position, data, mine, user }) => {
  return (
    <li className={mine ? "me" : ""}>
      <h3>{position}</h3>
      <article>
        <ProfilePicture user={data} />
        <div>
          <strong>{data?.prenom} {data?.nom?.substring(0, 1).toUpperCase()}.</strong>
          <p>{data?.points} point{parseInt(data?.points) > 0 ? "s" : ""}</p>
        </div>
      </article>
    </li>
  );
};

export default RanksItem;