import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Pin = ({ user, getUser }) => {
  const [pinIndex, setPinIndex] = useState(0);
  const [inputs, setInputs] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const digit1 = useRef();
  const digit2 = useRef();
  const digit3 = useRef();
  const digit4 = useRef();

  const handlerPinInput = (ev) => {
    const value = ev.target.value;


    setPinIndex((prevPinIndex) => {
      if (value.length === 2) {
        ev.target.value = value[0];
        if (prevPinIndex < 3) {
          inputs[prevPinIndex + 1].value = value[1];
          inputs[prevPinIndex + 1].focus();
          return prevPinIndex + 1;
        }
      }
      else if (value.length === 0) {
        if (prevPinIndex > 0) {
          inputs[prevPinIndex - 1].focus();
          return prevPinIndex - 1;
        }
      }

      // Si aucun cas n'est satisfait, retourner la valeur actuelle de pinIndex
      return prevPinIndex;
    });
  }

  const handleInputFocus = (ev) => {

    let emptyInputFind = false;

    for (let i = 1; i < inputs.length; i++) {
      if (inputs[i].value === '') {
        inputs[i - 1].focus();
        setPinIndex(i - 1);
        emptyInputFind = true;
        break;
      }
    }


    if (!emptyInputFind) {
      inputs[3].focus();
      setPinIndex(3);
    }

  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    let fullPin = digit1.current.value + digit2.current.value + digit3.current.value + digit4.current.value;

    if (fullPin === user.pin) {
      setError("");
      sessionStorage.setItem("logged", parseInt(Date.now()) + (12 * 3600000));
      sessionStorage.setItem("lang", user.lng);
      let respUser = await getUser(user.telephone);
      window.location.href = "/home";
      //navigate("/home");
    }
    else {
      setError("Code PIN incorrect !");
    }
  }

  useEffect(() => {
    digit1.current.focus();
    setInputs([digit1.current, digit2.current, digit3.current, digit4.current]);

    if(!document.body.classList.contains("fingers"))
      document.body.classList.add("fingers");
  }, [])
  return (
    <>
      <Header />
      <main className="pin-component">
        <h1>Votre code pin</h1>
        <form onSubmit={handleSubmit}>
          <div className="code-pin">
            <input type="number" id="digit1" ref={digit1} pattern="\d" onInput={handlerPinInput} onClick={handleInputFocus} required />
            <input type="number" id="digit2" ref={digit2} pattern="\d" onInput={handlerPinInput} onClick={handleInputFocus} required />
            <input type="number" id="digit3" ref={digit3} pattern="\d" onInput={handlerPinInput} onClick={handleInputFocus} required />
            <input type="number" id="digit4" ref={digit4} pattern="\d" onInput={handlerPinInput} onClick={handleInputFocus} required />
          </div>
          <button>
            Valider
            <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.0769 2L23 11.0011L14.0769 20M2 11.0034H23" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </form>
      </main>
      <Footer visible={false} />
    </>
  );
};

export default Pin;