import React, { useEffect, useState } from 'react';
import Paragraph from './Paragraph';

const SlideText = ({ slide, changeState, right }) => {
  const [paragraphs, setParagraphs] = useState([]);
  const [state, setState] = useState(0);
  const path = "https://connivence-salon.net/api/e-learning/assets/images/";

  const changeLocalState = (value) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto' // Cette ligne est facultative car 'auto' est le comportement par défaut
    });
    setState(value);
  }

  useEffect(() => {
    setParagraphs(slide.paragraphs);
    console.log(slide.correction)

    if (slide?.lesson === "1" && !document.body.classList.contains("lessons-bg")) {
      document.body.classList.add("lessons-bg");
    }
  }, [slide])
  return (
    <>
      {slide?.lesson === "1" ?
        <article className={"tplt-" + slide.template + " lesson"}>

          {
            paragraphs?.map((par, index) =>
              <Paragraph key={index} par={par} pos={index + 1} maxPos={paragraphs.length} visible={state === index ? true : false} changeLocalState={changeLocalState} changeState={changeState} />
            )
          }
        </article>
        :
        ""
      }
      {slide?.lesson === "0" && !(slide.correction === "1" && right === false) ?
        <article className={"tplt-" + slide.template} dangerouslySetInnerHTML={{ __html: slide.text_content }}>
        </article>
        :
        ""
      }
      {
        slide?.correction === "1" && right === false ?
          <article className='tplt-inline-text'>
            <div>
              <h3>Oups ! Ce n'est pas la bonne réponse...</h3>
              <p className='line-break'>... mais la réussite est l'aboutissement d'une succession d'échecs !</p>
              <strong>Pas de panique, je vais y arriver... Il faut persévérer !</strong>
            </div>
          </article>
          :
          ""
      }
    </>
  );
};

export default SlideText;