import React, { useEffect, useState } from 'react';

const SlideColorWheel = ({ slide, setPoints, points, changeState, setRight }) => {

  const [userAnswers, setUserAnswers] = useState([]);

  const correction = JSON.parse(slide.round_correction);

  const changeUserAnswers = (index, value) => {
    // Créer une copie du tableau
    let newArray = [...userAnswers];

    // Modifier la deuxième valeur dans la copie
    newArray[parseInt(index)] = parseInt(value);

    // Mettre à jour l'état avec la nouvelle copie du tableau
    setUserAnswers(newArray);
  }

  const handleChange = (ev) => {
    if (String(ev.target.value).length >= 1) {
      ev.target.blur();
      if (parseInt(ev.target.dataset.index) < parseInt(slide.round_inputs) - 1) {
        document.querySelector(".number-inputs").querySelectorAll("input")[parseInt(parseInt(ev.target.dataset.index) + 1)].focus();
      }
    }

    changeUserAnswers(parseInt(ev.target.dataset.index), ev.target.value);
  }

  const resetInputs = () => {
    let inputs = document.querySelector(".number-inputs").querySelectorAll("input");

    // Parcours chaque input et met sa valeur à ""
    inputs.forEach(function (input) {
      input.value = "";
    });
  }

  const checkAnswers = () => {
    let ready = true;
    userAnswers.forEach((answer) => {
      if (String(answer).length === 0) {
        ready = false;
      }
    });

    return ready;
  }

  const handleValidate = () => {
    if (userAnswers.length >= JSON.parse(slide.round_correction).length && checkAnswers()) {
      let sortedAnswers = userAnswers;
      sortedAnswers.sort(function (a, b) {
        return a - b;
      });

      if (JSON.stringify(correction) === JSON.stringify(sortedAnswers)) {
        setPoints(points + 20);
        console.log("bon");
        //resetInputs();
        changeState();
      }
      else {
        setRight(false);
        //resetInputs();
        changeState();
      }
    }
  }

  const handleClick = (ev) => {
    const id = parseInt(ev.target.dataset.id); // Récupérer l'ID du dataset
    let array_answers = [...userAnswers]; // Faire une copie pour éviter une mutation directe de l'état
  
    if (ev.target.classList.contains("selected")) {
      // Si déjà sélectionné, retirer
      ev.target.classList.remove("selected");
      array_answers = array_answers.filter((answer) => answer !== id); // Supprimer l'ID du tableau
    } else {
      // Sinon, ajouter
      ev.target.classList.add("selected");
      array_answers.push(id); // Ajouter l'ID
    }
  
    setUserAnswers(array_answers); // Mettre à jour l'état
  };

  useEffect(() => {
    setUserAnswers([])
    if (window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // Cette ligne est facultative car 'auto' est le comportement par défaut
      });
    }
  }, [slide])

  return (
    <article className={"tplt-" + slide.template}>
      <div className="question" dangerouslySetInnerHTML={{ __html: slide.question }}>

      </div>

      <svg width="184" height="196" viewBox="0 0 184 196" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M89.9296 0C88.8896 0.42 87.7596 0.39 86.6796 0.62C73.9396 3.3 65.1296 15.27 66.3596 28.27C67.5196 40.49 76.7696 50.09 88.8896 51.65C103.6 53.54 116.91 42.54 117.9 27.69C118.82 13.7 108.8 1.78 94.7896 0.21C94.6196 0.19 94.4196 0.22 94.3296 0H89.9296Z" fill="#E20613" className="svg-clickable" data-id="1" onClick={(ev) => handleClick(ev)}/>
        <path d="M157.6 38.4602C143.07 38.5202 131.66 50.1402 131.84 64.9102C132.01 79.2602 144.17 90.7302 158.64 90.2102C171.85 89.7402 183.56 78.9402 183.57 64.2002C183.57 49.9702 171.86 38.4002 157.61 38.4602H157.6Z" fill="#E84E1B" className="svg-clickable" data-id="2" onClick={(ev) => handleClick(ev)}/>
        <path d="M157.66 160.15C172.01 160.15 183.59 148.58 183.57 134.25C183.56 120 171.94 108.41 157.69 108.42C143.24 108.42 131.79 119.99 131.8 134.56C131.81 148.64 143.45 160.15 157.66 160.15Z" fill="#FEEC01" className="svg-clickable" data-id="3" onClick={(ev) => handleClick(ev)}/>
        <path d="M91.6303 143.95C77.1103 144.32 65.8503 156.56 66.4803 170.92C67.1203 185.57 79.5503 196.55 93.9103 195.67C108.2 194.79 118.95 182.62 118.17 168.41C117.38 154.13 105.38 143.54 91.6303 143.95Z" fill="#019640" className="svg-clickable" data-id="4" onClick={(ev) => handleClick(ev)}/>
        <path d="M0 137.49C0.49 138.59 0.52 139.81 0.81 140.96C3.46 151.32 12.47 159.14 23.05 160.19C36.84 161.57 49.06 152.49 51.37 138.87C53.99 123.37 42.99 110.69 29.59 108.84C15.19 106.85 2.06 116.78 0.21 131.11C0.19 131.28 0.23 131.48 0 131.55V137.49Z" fill="#0038FF" className="svg-clickable" data-id="5" onClick={(ev) => handleClick(ev)}/>
        <path d="M0 67.5402C0.5 68.6002 0.5 69.7802 0.79 70.9002C3.49 81.4302 12.39 89.1402 23.16 90.2402C37.48 91.7002 50.11 81.4802 51.63 67.1902C53.1 53.4402 43.24 40.7902 29.59 38.9102C15.13 36.9202 2.19 46.7202 0.22 61.1602C0.2 61.3402 0.23 61.5302 0 61.6002V67.5402Z" fill="#662483" className="svg-clickable" data-id="6" onClick={(ev) => handleClick(ev)}/>
      </svg>

      


      <button onClick={handleValidate}>
        Valider
      </button>
    </article>
  );
};

export default SlideColorWheel;